<template>
  <App title="编辑客户信息">
    <Form class="form full-container">
      <CellGroup>
        <Field
          v-model="formData.name"
          label="姓名"
          input-align="right"
          placeholder="请输入客户姓名"
        />
        <Field
          v-model="formData.genderText"
          label="用户性别"
          readonly
          clickable
          @click="pickerShow('gender')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <Field
          v-model="formData.birthday"
          label="生日"
          readonly
          clickable
          @click="birthdayDate.show = true"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <Field
          v-model="formData.city"
          label="常驻城市"
          readonly
          clickable
          @click="pickerShow('city')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <Cell
          center
          title="是否到店"
        >
          <template #right-icon>
            <vanSwitch
              v-model="formData.arrive"
              size="24"
            />
          </template>
        </Cell>
        <Cell
          center
          title="重要客户"
        >
          <template #right-icon>
            <vanSwitch
              v-model="formData.isImportant"
              size="24"
            />
          </template>
        </Cell>
      </CellGroup>
    </Form>
    <Popup
      v-model="picker.show"
      position="bottom"
    >
      <Picker
        show-toolbar
        value-key="label"
        :columns="picker.columns"
        @confirm="pickerConfirm"
        @cancel="picker.show = false"
      />
    </Popup>
    <Popup
      v-model="birthdayDate.show"
      position="bottom"
    >
      <DatetimePicker
        show-toolbar
        type="date"
        v-model="birthdayDate.currentDate"
        title="选择年月日"
        :min-date="birthdayDate.minDate"
        :max-date="birthdayDate.maxDate"
        @confirm="birthdayDateConfirm"
        @cancel="birthdayDate.show = false"
      />
    </Popup>
    <FooterBtn
      @cancel="chinal"
      @ok="onSave"
      okText="保存"
    ></FooterBtn>
  </App>
</template>

<script>
import { Form, Field, CellGroup, Cell, Switch, Popup, Picker, DatetimePicker, Toast } from 'vant'
import FooterBtn from '_c/footerBtn/index'
import { accountinfo } from '@/api/general/index'
import { accountupdate } from '@/api/clue/index'
import { getAreaTree } from '@/api/common'
import 'vant/lib/form/style'
import 'vant/lib/cell-group/style'
import 'vant/lib/cell/style'
import 'vant/lib/field/style'
import 'vant/lib/switch/style'
import 'vant/lib/popup/style'
import 'vant/lib/picker/style'
import 'vant/lib/datetime-picker/style'
import './index.less';
import { getBrandCity } from '@/api/chargequery/index'

export default {
  name: 'customerInfoEdit',
  components: { Form, Field, CellGroup, Cell, vanSwitch: Switch, Popup, Picker, DatetimePicker, FooterBtn },
  data () {
    return {
      cityName: {},
      formData: {
        name: '',
        genderText: '',
        genderValue: '',
        birthday: '',
        city: '',
        arrive: false,
        isImportant: false,
        cityCode: ''
      },
      picker: {
        show: false,
        columns: [],
        currKey: '',
        dataCollection: {
          gender: [],
          city: []
        }
      },
      birthdayDate: {
        show: false,
        currentDate: new Date(),
        minDate: new Date(1900, 0, 1),
        maxDate: new Date(3000, 11, 31)
      },
      cityList: []
    }
  },
  methods: {
    chinal () {
      this.$router.go(-1)
    },
    async onSave () {
      console.log('form', this.formData);
      const data = {
        "eosAccountId": this.$route.query.id,//客户ID
        "name": this.formData.name,//姓名
        "city": this.formData.cityCode,//常驻城市
        "isToshop": this.formData.arrive,//是否到店
        "gender": this.formData.genderValue,//男女
        "birthday": this.formData.birthday,//出生日期
        "isImportant": this.formData.isImportant//重要客户
      }
      const res = await accountupdate(data)
      if (res.success) {
        Toast.success('编辑成功')
        this.chinal()
      } else {
        Toast.error('编辑失败')
      }
    },
    // 性别
    genderList () {
      this.picker.dataCollection.gender = this.$storage.get('baseData')['gende'].map(item => {
        return {
          label: item.key,
          value: item.value
        }
      })
          this.getData()
    },
    // 获取省市数据
    getAreaList () {
      getAreaTree().then(res => {
        if (res.success) {
          this.cityList = res.data
          this.picker.dataCollection.city = res.data.map(e => {
            e.children.map(item => {
              delete item.children
              return item
            })
            return e
          })
          let city = ''
          let cityCode = ''
          this.cityList.forEach(el => {
            el.children.forEach(item => {
              if (item.value === this.formData.cityCode) {
                city = item.label + ',' + el.label
                cityCode = item.value
              }
            })
          })
          this.$set(this.formData, 'city', city)
          this.$set(this.formData, 'cityCode', cityCode)
        }
      })
    },
    changeAppointmentTimePicker (picker, value) {
      this.appointmentTime.columnsValue = value
    },
    findPathByLabels (treeData, labelArr) {
      const res = []
      const dfs = (treeData) => {
        if (!labelArr.length) return
        if (Array.isArray(treeData)) {
          for (const data of treeData) {
            const idx = labelArr.indexOf(data.label)
            if (idx > -1) {
              res.push(data)
              labelArr.splice(idx, 1)
            }
            dfs(data.children)
          }
        }
      }
      dfs(treeData)
      return res
    },
    pickerShow (key) {
      this.picker.currKey = key
      this.picker.show = true
      this.picker.columns = this.picker.dataCollection[key]
    },
    pickerConfirm (value) {

      this.picker.show = false
      this.formData[this.picker.currKey] = value
      if (this.picker.currKey === 'gender') {
        this.formData.genderText = value.label
        this.formData.genderValue = value.value
      }
      if (this.picker.currKey === 'city') {
        this.formData.city = value.join(',')
        const selected = this.findPathByLabels(this.picker.dataCollection.city, value)
        this.formData['provinceCode'] = selected[0].value
        this.formData['cityCode'] = selected[1].value
      }
    },
    birthdayDateConfirm (value) {
      this.formData.birthday = this.formatDate(value)
      this.birthdayDate.show = false
    },
    formatDate (date) {
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      return y + '-' + m + '-' + d
    },
    // 获取上牌城市
    async getBrandCity () {
      const res = await getBrandCity()
      if (res.code === '000000') {
        const k = {}
        res.data.map(e => {
          k[e.intentionCity] = e.cityName
        })
        this.cityName = k
      } else {
        Toast(res.msg)
      }
      return new Promise((resole) => resole())
    },
    async getData () {
      const res = await accountinfo({
        id: this.$route.query.id
      })
      if (res.success) {
        this.$storage.get('baseData')['gende'].forEach(el => {
          if (el.value === res.data.gender) {
            res.data.gender = { 'label': el.key, 'value': el.value }
          }
        })
        this.formData = {
          name: res.data.name,
          genderValue: res.data.gender.value,
          genderText: res.data.gender.label,
          city: '',
          cityCode: res.data.city,
          birthday: res.data.birthday == null ? '' : res.data.birthday === 'Invalid Date' ? '' : res.data.birthday,
          arrive: res.data.isToshop,
          isImportant: res.data.isImportant === 'true' ? true : false,
        }
      } else {
        Toast('请求数据失败')
      }
    },
    async init () {
      await this.genderList()
      await this.getAreaList()

    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
</style>